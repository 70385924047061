$title1: #9B9B9B;
$title2: #35B2A0;
$background: #2C2E3B;
$action-text: #2CC3F8;
$border: #318BCA ;
$button: #ED6E1F ;
$item-background: #494B5A ;


body {
    font-family: 'Saira', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    background-color: $background;
}




.page {
    display: flex;
    flex-direction: column;

    .content {
        max-width: 600px;
        padding-left: 32px;
        padding-right: 32px;
    }
}

.login {
    .content {
        display: flex;
        flex-direction: column;

        .title {
            color: $title1;
            font-size: 28px;
            font-weight: bold;
            margin-top: 56px;
        }

        .pinPad {
            margin-bottom: 56px;
            margin-top: 56px;
        }

        .no-pin {
            align-self: end;
            color: $action-text;
            text-transform: lowercase;
            font-weight: normal;
        }
    }
}

.home {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    .content {
        flex-grow: 1;
        overflow-y: auto;
    }

    .MuiBottomNavigation-root {
        background-color: #E9E9EB;
    }
}

.scanner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 16px;
    margin-right: 16px;


    header {
        color: $title1;
        font-size: 20px;
        font-weight: bold;
        margin-top: 32px;
    }

    .scan {
        margin-top: 56px;
        background-color: $button;
        border-radius: 32px;
        width: 200px;
        font-size: 20px;
    }

    .gate {
        box-sizing: border-box;
        margin-top: 56px;
        background: $item-background;
        color: #fff;
        padding-top: 32px;
        padding-bottom: 32px;
        padding-left: 16px;
        padding-right: 16px;
        width: 100%;
        font-size: 20px;
        text-transform: uppercase;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-radius: 8px;

        span {
            color: $action-text;
        }
    }
}

.scan-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 16px;
    margin-right: 16px;

    header {
        color: $title1;
        font-size: 20px;
        font-weight: bold;
        margin-top: 32px;
        width: 100%;
        line-height: 36px;
        margin-bottom: 48px;
    }


    .message {
        box-sizing: border-box;
        margin-top: 48px;
        color: #fff;
        padding-top: 32px;
        padding-bottom: 32px;
        padding-left: 16px;
        padding-right: 16px;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        font-size: 18px;
        text-align: center;

        p {
            margin: 0;
        }

        &.success {
            background-color: #2C6D69;
        }

        &.fail {
            background-color: #7B1A23;
        }
    }

    .scan {
        margin-top: 32px;
        background-color: $button;
        border-radius: 32px;
        font-size: 20px;
    }

    .gate span {
        color: $action-text;
        margin-left: 1em;
    }
}

.event-list {
    margin-top: 32px;

    .search-box {
        border: solid 2px $border;
        border-radius: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $action-text;

        svg {
            margin-left: 8px;
        }

        input {
            border: none;
            background: none;
            flex-grow: 1;
            color: $action-text;
            padding: 8px;
            padding-top: 8px;
            padding-bottom: 8px;

            &::placeholder {
                color: $action-text;
                opacity: 0.8;
            }

            &:focus {
                outline: none;
            }
        }
    }

    .list-view {
        margin-top: 32px;

        .list-item {
            background-color: $item-background;
            padding: 16px;
            margin-bottom: 4px;
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            cursor: pointer;
            user-select: none;

            header {
                color: $title2;
                font-weight: bold;
            }

            .main {
                color: #fff;
                flex-grow: 1;
                font-size: 14px;
            }

            .status {
                display: flex;
                flex-direction: column;
                background-color: #EBE7E6;
                border-radius: 6px;
                padding-left: 1px;
                padding-right: 2px;
                margin-left: 8px;

                span {
                    opacity: 0.1;
                    line-height: 20px;

                    &.red {
                        color: #8C0D16;
                    }

                    &.yellow {
                        color: #F1AB3C;
                    }

                    &.green {
                        color: #43CCC4;
                    }
                }


                &.active .green {
                    opacity: 1;
                }
            }
        }
    }

    .change-pin {
        margin-top: 32px;
        background-color: $button;
        border-radius: 32px;
        align-self: center;
    }
}

.pinPad {
    display: flex;
    flex-direction: column;
    align-items: center;

    .value {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 32px;

        span {
            border-radius: 50%;
            border: solid 3px $border;
            width: 16px;
            height: 16px;
            display: block;
            text-align: center;
            line-height: 16px;
            color: #fff;
            font-size: 14px;
            margin-right: 12px;
            margin-left: 12px;

            &.empty {
            }

            &.hidden {
                background-color: $border;
                color: $border;
            }
        }
    }

    .buttons {
        display: inline-grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 32px;
        row-gap: 24px;
        justify-content: center;

        button {
            border-radius: 50%;
            width: 64px;
            height: 64px;
            border: 2px solid $border;
            background-color: transparent;
            font-size: 28px;
            line-height: 64px;

            svg {
                fill: #ED6E1F;
            }
        }
    }
}

.scan-preview {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: black;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition-duration: 0.3s;
    transform: scale(0, 0);

    &.hidden {
        display: none;
    }

    &.visible {
        display: flex;
        opacity: 1;
        transform: scale(1, 1);
    }

    video {
        max-width: 100%;
        max-height: 100%;
    }

    .video-container {
        position: relative;
        /*
        &:after {
            position: absolute;
            display: block;
            height: 2px;
            left: 0;
            width: 100%;
            top: 50%;
            background-color: red;
            content: "";
            z-index: 100;
            animation: up-down;
            animation-iteration-count: infinite;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-direction: normal;
            background-blend-mode: exclusion;
        }*/
    }

    .focus-container {
        position: absolute;
        top: 16px;
        width: 100%;
        flex-direction: row;
        color: #fff;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
        display: none;

        &.visible {
            display: flex;
        }
    }

    .focus {
        display: block;
        flex-grow: 1;
        margin-right: 32px;
        margin-left: 16px;
        box-sizing: border-box;
    }

    .cancel-scan {
        position: absolute;
        bottom: 32px;
        border: none;
        background-color: #29b6f6;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        padding: 12px 32px 12px 32px;
        border-radius: 4px;

        &:active {
            background-color: #4fc3f7;
        }
    }
}